<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-4.png"
    ></v-img>

    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Page Not Found</span>
        <v-icon color="warning">
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>
      <p class="text-sm">
        we couldn't find the page you are looking for
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="400"
          src="~@/assets/images/TV_M201_010.png"
        ></v-img>
      </div>
      <!-- tw-md:tw-grid-rows-2 tw-md:tw-grid-cols-1 -->
      <div class="md:tw-grid md:tw-grid-cols-4 md:tw-gap-4 ">
        <div></div>
        <div>
          <v-btn
            color="error"
            class="mb-4"
            @click="logout"
          >
            Reset Connection
          </v-btn>
        </div>
        <div>
          <v-btn
            color="primary"
            to="/"
            class="mb-4"
          >
            Back to home
          </v-btn>
        </div>
        <div></div>
      </div>
    </div>
    <v-card-text class="text-center">
      <p class="text-xl font-weight-medium text--primary mb-1">
        Need help? Chat or Email us now!
      </p>
    </v-card-text>

    <v-row class="mx-auto">
      <v-col
        cols="12"
        sm="6"
        md="5"
        class="ms-auto"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-btn
              icon
              @click="showIntercom"
            >
              <v-icon
                class="text--primary"
                size="24"
              >
                {{ icons.mdiForumOutline }}
              </v-icon>
            </v-btn>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            <a @click="showIntercom">Click Here to open Chat</a>
          </p>

          <p class="text-sm mb-0">
            Message us now to help you faster
          </p>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="5"
        class="me-auto"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-btn
              icon
              href="mailto:info@pharmaguide.ca"
            >
              <v-icon
                class="text--primary"
                size="24"
              >
                {{ icons.mdiEmailOutline }}
              </v-icon>
            </v-btn>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            <a href="mailto:info@pharmaguide.ca">info@pharmaguide.ca</a>
          </p>

          <p class="text-sm mb-0">
            Best way to get answer faster!
          </p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiAlert, mdiEmailOutline, mdiForumOutline } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
        mdiEmailOutline,
        mdiForumOutline,
      },
    }
  },
  mounted() {
    this.bootIntercom()
  },
  methods: {
    bootIntercom() {
      // const id = this.$auth.user.sub.replace('auth0|', '')
      this.$intercom.boot({
        // user_id: id,
        // name: this.$auth.user.name,
        // email: this.$auth.user.email,
        // pharmacy: this.PharmacyProfile.name,
        // 'Company Name': this.PharmacyProfile.name,
        // DBType: this.PharmacyProfile.DBType,
        // PharmacyEmail: this.PharmacyProfile.email,
      })
    },
    logout() {
      this.$router.push('/')
      this.$auth.logout({
        // returnTo: 'https://phox.pharmaguide.ca/login',
        // 'http://localhost:8080/login',
      })

      sessionStorage.clear()
      localStorage.clear()
      location.reload()
    },
    showIntercom() {
      Intercom('showNewMessage', 'Hey, I would like some help for')
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
